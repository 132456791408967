import React from "react"
import { Layout } from "../components"

function TOU() {
  return (
    <Layout title="Privacy Policy">
      <div className="text-justify px-10 md:px-20">
        <h1 className="mb-10 md:md-20">Privacy Policy</h1>
        <p style={{ textAlign: "justify" }} className="mb-4">
          This is the privacy policy (
          <strong>&ldquo;Privacy Policy&rdquo;</strong>) that governs how we,
          <strong>
            Livecycle Technologies Ltd. (&ldquo;Livecycle&rdquo;,
            &ldquo;we&rdquo;, &ldquo;our&rdquo; or &ldquo;us&rdquo;)
          </strong>
          , use Personal Information (defined below) that we collect, receive
          and store about individuals in connection with the use of: (i) the
          website <a href="https://livecycle.io/">https://livecycle.io/</a>
          ,&nbsp; and any other website that we operate (each, together with its
          sub-domains, content and services, a &ldquo;Site&rdquo;); and (ii) the
          Livecycle platform (the &ldquo;<strong>Platform</strong>&rdquo;). The
          Site and the Platform are individually and collectively referred to
          herein as the &ldquo;<strong>Services</strong>&rdquo;.&nbsp;
        </p>
        <p className="mb-4">
          We do not ourselves host any of the Services – all hosting is done by
          third party service providers that we engage. This means that data you
          provide us or that we collect from you (including any Personal
          Information, as defined below) – as further described in this Privacy
          Policy – is hosted with such third party service providers on servers
          that they own or control. Regardless of where such third party service
          providers are located (and some are located in the US), their servers
          may be located anywhere in the world (including the US). Your data may
          even be replicated across multiple servers located in multiple
          countries. So please be aware that you are consenting to your data
          being transferred to various third party service providers around the
          world (including the US).
        </p>
        <ol className="mb-4">
          <li>
            <p style={{ textAlign: "justify" }}>
              <strong>Introduction.</strong> We have implemented this Privacy
              Policy because your privacy, and the privacy of other users, is
              important to us. This Privacy Policy explains our online
              information practices and the choices you can make about the way
              your Personal Information is collected and used in connection with
              the Services. <strong>“Personal Information”</strong> means any
              information that may be used, either alone or in combination with
              other information, to personally identify an individual,
              including, but not limited to, a first and last name, a personal
              profile, an email address, a home or other physical address, or
              other contact information.
            </p>
          </li>
          <li>
            <p style={{ textAlign: "justify" }}>
              <strong>Consent and Modification.</strong> You are not legally
              obligated to provide us Personal Information, and you hereby
              confirm that providing us Personal Information is at your own free
              will. By using the Services, you consent to the terms of this
              Privacy Policy and to our collection, processing and sharing of
              Personal Information for the purposes set forth herein. If you do
              not agree to this Privacy Policy, please do not access or
              otherwise use the Services. We reserve the right, at our
              discretion, to change this Privacy Policy at any time. Such change
              will be effective ten (10) days following posting of the revised
              Privacy Policy on the Site, and your continued use of the Services
              thereafter means that you accept those changes.
            </p>
          </li>
          <li>
            <p style={{ textAlign: "justify" }}>
              <strong>
                What Personal Information We Collect and How We Collect It.
              </strong>
              We do not currently require you to provide Personal Information in
              order to have access to general information available on the Site.
              But, we do receive and/or collect Personal Information from you in
              the following ways:
            </p>
          </li>
          <ol style={{ listStyleType: "lower-alpha" }}>
            <li>
              <p style={{ textAlign: "justify" }}>
                <strong>Account.</strong> In order to use certain services that
                we offer via the Platform, you may be required to create an
                account (“
                <strong>Account</strong>”). If you create an Account you will be
                required to provide us with certain information, such as your
                email address (“<strong>Account Information</strong>”). You may
                also choose to provide additional Personal Information as part
                of the Account registration process and in connection with your
                ongoing use of your Account. If you choose not to create an
                Account, you may not be able to access or use the Platform.
              </p>
              <p>
                Regardless of which method you choose to register your Account,
                we may send an email to your nominated email address to instruct
                you how to confirm your registration.
              </p>
            </li>
            <li>
              <p style={{ textAlign: "justify" }}>
                <strong>‘Contact Us’ Information.</strong> If you send us a
                “Contact Us” request, whether by submitting an online form that
                we make available or by sending an email to an email address
                that we display, you may be required to provide us with certain
                information such as your name and email address.
              </p>
            </li>
            <li>
              <p style={{ textAlign: "justify" }}>
                <strong>Log Files.</strong> We may make use of log files. The
                information inside the log files includes internet protocol (IP)
                addresses, type of browser, Internet Service Provider (ISP),
                date/time stamp, referring/exit pages, clicked pages and any
                other information your browser may send to us. We may use such
                information to analyze trends, administer the Services, track
                users’ movement around the Services, and gather demographic
                information.
              </p>
            </li>
            <li>
              <p style={{ textAlign: "justify" }}>
                <strong>Cookies and Other Tracking Technologies.</strong> Our
                Services may utilize “cookies”, anonymous identifiers and other
                tracking technologies in order to for us to provide our Services
                and present you with information that is customized for you. A
                “cookie” is a small text file that may be used, for example, to
                collect information about activity on the Services. Certain
                cookies and other technologies may serve to recall Personal
                Information, such as an IP address, previously indicated by a
                user. Most browsers allow you to control cookies, including
                whether or not to accept them and how to remove them. You may
                set most browsers to notify you if you receive a cookie, or you
                may choose to block cookies with your browser.
              </p>
            </li>
          </ol>
          <li>
            <p style={{ textAlign: "justify" }}>
              <strong>
                The Way We Use Personal Information. and Other Tracking
                Technologies.
              </strong>{" "}
              If you submit or we collect Personal Information through the Site,
              then we may use such Personal Information in the following ways,
              via any applicable communication channel, including e-mail, SMS,
              etc.:
            </p>
            <ol style={{ listStyleType: "lower-alpha" }}>
              <li>
                <p>
                  We will use your Personal Information to provide and improve
                  our Services, to send you marketing/advertisement
                  communications that we believe may be of interest to you, to
                  contact you in connection with the Services and certain
                  programs or offerings that you may have registered for, and to
                  identify and authenticate your access to the parts of the
                  Services that you are authorized to access.
                </p>
              </li>
              <li>
                <p>
                  We may use your designated email address to: (i) send you
                  updates or news regarding the Services and our products;
                  and/or (ii) respond to a “Contact Us” or administrative
                  request (for example, to change your password).
                </p>
              </li>
              <li>
                <p>
                  We may transfer your Personal Information to our local or
                  foreign subsidiaries or affiliated companies for the purpose
                  of storing or processing such information on our behalf. Such
                  information may be transferred to other countries. We require
                  that these parties agree to process such information in
                  compliance with our Privacy Policy.
                </p>
              </li>
              <li>
                <p>
                  We may share your Personal Information with our third party
                  service providers and partners, but only to assist us with our
                  business operations and to provide our Services to you and
                  other users. Such information may be transferred to other
                  countries. We use commercially reasonable efforts to only
                  engage or interact with third party service providers and
                  partners that post a privacy policy governing their processing
                  of Personal Information.
                </p>
              </li>
              <li>
                <p>
                  We may disclose your Personal Information or any information
                  you submitted via the Services if we have a good faith belief
                  that disclosure of such information is helpful or reasonably
                  necessary to: (i) comply with any applicable law, regulation,
                  legal process or governmental request; (ii) detect, prevent,
                  or otherwise address fraud or security issues; or (iii)
                  protect against harm to the rights, property or safety of
                  Livecyle, our users, yourself or the public.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p style={{ textAlign: "justify" }}>
              <strong>Use of Anonymous Information.</strong> We may use
              Anonymous Information (as defined below) or disclose it to third
              party service providers in order to improve our Services and
              enhance your experience with the Services. We may also disclose
              Anonymous Information (with or without compensation) to third
              parties, including advertisers and partners. “
              <strong>Anonymous Information</strong>” means information which
              does not enable identification of an individual user, such as
              aggregated information about the use of our Services.
            </p>
          </li>
          <li>
            <p style={{ textAlign: "justify" }}>
              <strong>Opting Out.</strong> You may choose not to receive future
              promotional, advertising, or other Services-related emails from us
              by selecting an unsubscribe link at the bottom of each email that
              we send. Please note that even if you opt out of receiving the
              foregoing emails, we may still send you a response to any “Contact
              Us” request as well as administrative emails (for example, in
              connection with a password reset request) that are necessary to
              facilitate your use of the Services.
            </p>
          </li>
          <li>
            <p style={{ textAlign: "justify" }}>
              <strong>Choice.</strong> At all times, you may choose whether or
              not to provide or disclose Personal Information. If you choose not
              to provide mandatory Personal Information, you may still visit
              parts of the Services but you may be unable to access certain
              options, programs, offers, and services that involve our
              interaction with you.
            </p>
          </li>
          <li>
            <p style={{ textAlign: "justify" }}>
              <strong>Access/Accuracy.</strong> To the extent that you do
              provide us with Personal Information, we wish to maintain accurate
              Personal Information. If you would like to delete or correct any
              of your other Personal Information that we may be storing, you may
              use the tools that we make available on the Services or you may
              submit an access request by sending an email to{" "}
              <a href="mailto:support@livecycle.">support@livecycle.io</a>. Your
              email should include adequate details of your request.
            </p>
          </li>
          <li>
            <p style={{ textAlign: "justify" }}>
              <strong>
                Links to and Interaction with Third Party Products.
              </strong>{" "}
              The Services may enable you to interact with or contain links to
              your Third Party Account and other third party websites, mobile
              software applications and services that are not owned or
              controlled by us (each a “<strong>Third Party Service</strong>”).
              We are not responsible for the privacy practices or the content of
              such Third Party Services. Please be aware that Third Party
              Services may collect Personal Information from you. Accordingly,
              we encourage you to read the terms and conditions and privacy
              policy of each Third Party Service that you choose to use or
              interact with.
            </p>
          </li>
          <li>
            <p style={{ textAlign: "justify" }}>
              <strong>Children’s Privacy.</strong> The Services are not
              structured to attract children under the age of 18 years.
              Accordingly, we do not intend to collect Personal Information from
              anyone we know to be under 18 years. If we learn that we have
              collected Personal Information from a child under 18 years, we
              will delete that information as quickly as possible. If you
              believe that we might have any such information, please contact us
              at <a href="mailto:support@livecycle.">support@livecycle.io</a>.
            </p>
          </li>
          <li>
            <p style={{ textAlign: "justify" }}>
              <strong>Security.</strong> The security of Personal Information is
              important to us. We follow generally accepted industry standards,
              including the use of appropriate administrative, physical and
              technical safeguards, to protect the Personal Information
              submitted to us. However, no method of transmission over the
              Internet, or method of electronic storage, is 100% secure.
              Therefore, while we strive to use reasonable acceptable means to
              protect your Personal Information, we cannot guarantee its
              absolute security or confidentiality. If you have any questions
              about security on the Service, you can contact us{" "}
              <a href="mailto:support@livecycle.">support@livecycle.io</a>.
            </p>
          </li>
          <li>
            <p style={{ textAlign: "justify" }}>
              <strong>Merger, Sale or Bankruptcy.</strong> In the event that we
              are acquired by or merged with a third party entity, or in the
              event of bankruptcy or a comparable event, we reserve the right to
              transfer or assign Personal Information in connection with the
              foregoing events.
            </p>
          </li>
          <li>
            <p style={{ textAlign: "justify" }}>
              <strong>California Privacy Rights.</strong> California Civil Code
              Section 1798.83 permits our customers who are California residents
              to request certain information regarding our disclosure of
              Personal Information to third parties for their direct marketing
              purposes. To make such a request, please send an email to
              <a href="mailto:support@livecycle.">support@livecycle.io</a>.
              Please note that we are only required to respond to one request
              per customer each year.
            </p>
          </li>
          <li>
            <p style={{ textAlign: "justify" }}>
              <strong>Our California Do Not Track Notice.</strong> We do not
              track consumers over time and across third party websites and
              therefore do not respond to Do Not Track signals. We do not allow
              third parties to collect personally identifiable information about
              an individual consumer’s online activities over time and across
              different web sites when a consumer uses the Services.
            </p>
          </li>
          <li>
            <p style={{ textAlign: "justify" }}>
              <strong>Deletion of Content from California Residents.</strong> If
              you are a California resident under the age of 18 and a registered
              user, California Business and Professions Code Section 22581
              permits you to remove content or Personal Information you have
              publicly posted. If you wish to remove such content or Personal
              Information and you specify which content or Personal Information
              you wish to be removed, we will do so in accordance with
              applicable law. Please be aware that after removal you will not be
              able to restore removed content. In addition, such removal does
              not ensure complete or comprehensive removal of the content or
              Personal Information you have posted and that there may be
              circumstances in which the law does not require us to enable
              removal of content.
            </p>
          </li>
          <li>
            <p style={{ textAlign: "justify" }}>
              <strong>Commitment.</strong> We are committed to protecting your
              privacy. Protecting your privacy online is an evolving area, and
              we are constantly evolving our Service to meet these demands. If
              you have any comments or questions regarding our Privacy Policy,
              or your Personal Information that we may be storing and using,
              please contact us at{" "}
              <a href="mailto:support@livecycle.">support@livecycle.io</a>.
            </p>
          </li>
        </ol>
        <p style={{ textAlign: "justify" }}>
          <span style={{ fontWeight: "bold", fontStyle: "italic" }}>
            Last updated: April 2021
          </span>
        </p>
      </div>
    </Layout>
  )
}

export default TOU
